<div style="overflow: hidden;">
    <mat-toolbar class="internal-toolbar-consulti">
        <div class="flex-row space-between-center gap24 fxFlex">
            <span class="miniTitle" i18n>Consulti</span>
            <button mat-icon-button color="primary" matTooltip="Resetta filtri" i18n-matTooltip
                (click)="filtriReset()"><mat-icon>filter_alt_off</mat-icon></button>
            <div class="flex-col fxFlex">
                <span style="font-size: small;" i18n>Filtra per cliente</span>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <mat-select placeholder="Cliente" [ngModel]="clienteFilter" i18n-placeholder
                        (selectionChange)="clienteFilterChanged($event)" multiple>
                        <mat-option *ngFor="let cliente of filteredClienti()" [value]="cliente.Email">{{cliente.Nome}}
                            {{cliente.Cognome}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex-col fxFlex">
                <span style="font-size: small;" i18n>Filtra per paziente</span>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <mat-select placeholder="Paziente" [ngModel]="pazienteFilter" i18n-placeholder
                        (selectionChange)="pazienteFilterChanged($event)" multiple>
                        <mat-option *ngFor="let paziente of pazientiFiltered()"
                            [value]="paziente.Id">{{paziente.Nome}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="flex-col">
                    <span style="font-size: small;" i18n>Teleconferenza</span>
                    <mat-button-toggle-group name="tcSwitch" class="toggleGroup" [(ngModel)]="teleconferenzaFilter"
                        (change)="teleconfFilterChanged()">
                        <mat-button-toggle [value]="1" i18n>Teleconferenza</mat-button-toggle>
                        <mat-button-toggle [value]="2" i18n>No Teleconferenza</mat-button-toggle>
                    </mat-button-toggle-group>
                </div> -->
            <!-- <div class="flex-col">
                    <span style="font-size: small;" i18n>Pagamento</span>
                    <mat-button-toggle-group name="statoSwitch" class="toggleGroup" [(ngModel)]="statoPagamentoFilter"
                        (change)="statoPagamentoFilterChanged()">
                        <mat-button-toggle [value]="1" i18n>In Sospeso</mat-button-toggle>
                        <mat-button-toggle [value]="2" i18n>Effettuato</mat-button-toggle>
                    </mat-button-toggle-group>
                </div> -->
            <button mat-raised-button color="primary" class="newBtn" [routerLink]="'new'">NEW</button>
        </div>
    </mat-toolbar>

    <mat-table mat-table [dataSource]="listaConsulti" style="overflow: auto; height: calc(100vh - 160px);">
        <ng-container matColumnDef="utente">
            <mat-header-cell *matHeaderCellDef i18n> Richiesto da: </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.User.Nome}}&nbsp; {{element.User.Cognome}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="inData">
            <mat-header-cell *matHeaderCellDef i18n> In Data: </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.CreatedAt | date: 'dd/MM/yy' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="paziente">
            <mat-header-cell *matHeaderCellDef i18n> Paziente </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.Paziente.Nome}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tipoConsulto">
            <mat-header-cell *matHeaderCellDef i18n> Tipo </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{getTipoConsulto(element.TipoConsultoID)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tele">
            <mat-header-cell *matHeaderCellDef i18n>
                <div class="flex-col start-center">
                    <span>Teleconferenza</span>
                    <button mat-icon-button (click)="teleconfFilterChanged()" 
                        [matTooltip]="getTeleconferenzaFilterTooltip()">
                        <mat-icon *ngIf="teleconferenzaFilter == -1" color="primary">filter_list_off</mat-icon>
                        <mat-icon *ngIf="teleconferenzaFilter == 0" color="accent">history</mat-icon>
                        <mat-icon *ngIf="teleconferenzaFilter == 1" color="primary">history_off</mat-icon>
                        <mat-icon *ngIf="teleconferenzaFilter == 2" color="primary">lock_clock</mat-icon>

                    </button>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div *ngIf="element.Slot">
                    <span [ngClass]="{
                        'teleAccettata': element.Slot.Accettato === true, 
                        'teleRifiutata': element.Slot.Accettato === false,
                        'teleSospesa': element.Slot.Accettato === undefined
                     }">{{element.Slot.DataInizio | date: "short"}} </span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="statoPagamento">
            <mat-header-cell *matHeaderCellDef>
                <div class="flex-col start-center">
                    <span i18n>
                        Stato Pagamento
                    </span>
                    <button mat-icon-button (click)="statoPagamentoFilterChanged()" 
                        [matTooltip]="statoPagamentoFilterGetTooltip()">
                        <mat-icon *ngIf="statoPagamentoFilter == -1">filter_list_off</mat-icon>
                        <mat-icon *ngIf="statoPagamentoFilter == 1" color="primary">verified</mat-icon>
                        <mat-icon *ngIf="statoPagamentoFilter == 0" color="accent">unpublished</mat-icon>
                    </button>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <span
                    [ngClass]="{'pagamentoAccettato': element.Pagamento.Stato == pagamentoEffettuato, 'pagamentoSospeso': element.Pagamento.Stato == pagamentoSospeso}">{{element.Pagamento.Stato}}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="statoFattura">
            <mat-header-cell *matHeaderCellDef i18n>
                <div class="flex-col start-center">
                    <span>Stato Fattura</span>
                    <button mat-icon-button  (click)="statoFatturaFilterChanged()"
                        [matTooltip]="statoFatturaFilterGetTooltip()">
                        <mat-icon *ngIf="statoFatturaSearch == -1">filter_list_off</mat-icon>
                        <mat-icon *ngIf="statoFatturaSearch == 0" color="accent">{{ICOFatturaInviata}}</mat-icon>
                        <mat-icon *ngIf="statoFatturaSearch == 1" color="primary">{{ICOFatturaRiconciliata}}</mat-icon>
                        <mat-icon *ngIf="statoFatturaSearch == 2" style="color: var(--error);">{{ICOFatturaConErrori}}</mat-icon>
                        <mat-icon *ngIf="statoFatturaSearch == 3" style="color: var(--error);">{{ICOFatturaNonInviata}}</mat-icon>
                    </button>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <app-stato-fattura [consulto]="element"></app-stato-fattura>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="statoReferto">
            <mat-header-cell *matHeaderCellDef i18n>
                <div class="flex-col start-center">
                    <span>
                        Stato Referto
                    </span>
                    <button mat-icon-button (click)="statoRefertoFilterChanged()" 
                        [matTooltip]="getStatoRefertoFilterTooltip()">
                        <mat-icon *ngIf="statoRefertoFilter == -1">filter_list_off</mat-icon>
                        <mat-icon *ngIf="statoRefertoFilter == 0" color="accent">unpublished</mat-icon>
                        <mat-icon *ngIf="statoRefertoFilter == 1" color="primary">unpublished</mat-icon>
                        <mat-icon *ngIf="statoRefertoFilter == 2" color="primary">verified</mat-icon>
                    </button>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-icon *ngIf="statoReferto(element) == 0 " style="color: var(--accent);" i18n-matTooltip
                    matTooltip="Il referto non è ancora stato prodotto">unpublished</mat-icon>
                <mat-icon *ngIf="statoReferto(element) == 1" style="color: var(--primary);" i18n-matTooltip
                    matTooltip="Il referto è stato prodotto ma non è ancora disponibile al cliente">unpublished</mat-icon>
                <mat-icon *ngIf="statoReferto(element) == 2" style="color: var(--primary);" i18n-matTooltip
                    matTooltip="Il referto è disponibile per il cliente">verified</mat-icon>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-header"></mat-header-row>
        <mat-row *matRowDef="let consulto; columns: displayedColumns" style="cursor: pointer;"
            [routerLink]="consulto.ID" ></mat-row>
    </mat-table>

</div>