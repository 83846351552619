<!-- ECOCARDIOGRAFIA -->

<fieldset 
    [disabled]="isCliente" 
    *ngIf="referto != undefined && (!isCliente || (isCliente && referto.Disponibile))" 
    style="border: none!important;padding: 0px!important;" #ecocardioWrapper>
    <!-- GETTER PER VALUTARE STATO FORM -->
    <div style="visibility: hidden;">{{hasForm}}</div>
    <!-- GETTER PER VALUTARE STATO FORM -->

    <form #f="ngForm" class="flex-col gap12 start-stretch" style="height: calc(100vh - 386px);overflow: auto" >
        <div class="flex-col gap12">
            <mat-divider></mat-divider>
            <span class="miniTitle" i18n>Considerazioni preliminari</span>
            <mat-divider></mat-divider>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <mat-label>Motivo dell'esame</mat-label>
                <textarea matInput name="MotivoEsame" placeholder="Motivo esame"
                    [(ngModel)]="referto.MotivoEsame" i18n-placeholder></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <mat-label i18n>Anamnesi</mat-label>
                <textarea matInput name="Anamnesi" placeholder="Anamnesi" [(ngModel)]="referto.Anamnesi" i18n-placeholder></textarea>
            </mat-form-field>
        </div>
        <div class="flex-col gap12">
            <mat-divider></mat-divider>
            <span class="miniTitle" i18n>CAMPI PER ECOCARDIOGRAFIA</span>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label>Note</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <textarea matInput name="Note" placeholder="Note"
                        [(ngModel)]="referto.Note" i18n-placeholder ></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Sensorio</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="Sensorio" placeholder="Sensorio" [(ngModel)]="referto.Sensorio" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Mucose</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="Mucose" placeholder="Mucose" [(ngModel)]="referto.Mucose" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Frequenza respiratoria</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="FrequenzaRespiratoria" placeholder="Frequenza Respiratoria" i18n-placeholder
                    [(ngModel)]="referto.FrequenzaRespiratoria">
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Soffi cardiaci</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="SoffiCardiaci" placeholder="Soffi cardiaci" [(ngModel)]="referto.SoffiCardiaci" i18n-placeholder >
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Frequenza cardiaca</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="FrequenzaCardiaca" placeholder="Frequenza cardiaca" [(ngModel)]="referto.FrequenzaCardiaca" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>TRC</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="Trc" placeholder="TRC" [(ngModel)]="referto.Trc" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div class="flex-col gap12" >
            <span class="miniTitle" i18n>ECOCARDIOGRAFIA</span>
            <mat-divider></mat-divider>
            <mat-label i18n>M-mode ventricolo sinistro diastole</mat-label>
            <div class="flex-row space-between-center gap12">
                <div class="flex-col fxFlex">
                    <mat-label class="labelInputWithSuffix" i18n>Setto I.V. d</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic" style="border-radius:0px 24px 24px 0px;" class="inputWithSuffix">
                        <input matInput name="MModeVentricoloSinistroDiastolesettoivd" placeholder="Setto I.V. d" [(ngModel)]="referto.MModeVentricoloSinistroDiastolesettoivd" i18n-placeholder>
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label class="labelInputWithSuffix">D.V. sx d</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic" style="border-radius:0px 24px 24px 0px;" class="inputWithSuffix">
                        <input matInput name="MModeVentricoloSinistroDiastoledvsxd" placeholder="D.V. sx d" [(ngModel)]="referto.MModeVentricoloSinistroDiastoledvsxd">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label class="labelInputWithSuffix">PLV sx d</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix" subscriptSizing="dynamic" style="border-radius:0px 24px 24px 0px;" >
                        <input matInput name="MModeVentricoloSinistroDiastoleplvsxd" placeholder="PLV sx d" [(ngModel)]="referto.MModeVentricoloSinistroDiastoleplvsxd">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div class="flex-col gap12">
            <mat-label i18n>M-mode ventricolo sinistro s</mat-label>
            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix" i18n>Setto I.V. s</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="MModeVentricoloSinistroSSettoivs" placeholder="Setto I.V. s" i18n-placeholder [(ngModel)]="referto.MModeVentricoloSinistroSSettoivs">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">D.V. sx s</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="MModeVentricoloSinistroSDvsxs" placeholder="D.V. sx s" [(ngModel)]="referto.MModeVentricoloSinistroSDvsxs">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">PLV sx s</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="MModeVentricoloSinistroSPlvsxs" placeholder="PLV sx s" [(ngModel)]="referto.MModeVentricoloSinistroSPlvsxs">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex-col gap12">
            <mat-label i18n>M-mode ventricolo sinistro</mat-label>
            <div class="flex-row space-between-center" style=" max-width: calc(100vw - 280px)!important;">
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">F.A.</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix smaller" subscriptSizing="dynamic">
                        <input matInput name="MModeVentricoloSinistroFa" placeholder="F.A." [(ngModel)]="referto.MModeVentricoloSinistroFa" >
                        <span matSuffix class="inputSuffix">%</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">F.E.</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix smaller" subscriptSizing="dynamic">
                        <input matInput name="MModeVentricoloSinistroFe" placeholder="F.E." [(ngModel)]="referto.MModeVentricoloSinistroFe">
                        <span matSuffix class="inputSuffix">%</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">ESVI</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix smaller" subscriptSizing="dynamic">
                        <input matInput name="MModeVentricoloSinistroEsvi" placeholder="ESVI" [(ngModel)]="referto.MModeVentricoloSinistroEsvi">
                        <span matSuffix class="inputSuffix">ml/m2</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">EDVI</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix smaller" subscriptSizing="dynamic">
                        <input matInput name="MModeVentricoloSinistroEdvi" placeholder="EDVI" [(ngModel)]="referto.MModeVentricoloSinistroEdvi">
                        <span matSuffix class="inputSuffix">ml/m2</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">F. card.</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix smaller" subscriptSizing="dynamic">
                        <input matInput name="MModeVentricoloSinistroFcard" placeholder="F. card." [(ngModel)]="referto.MModeVentricoloSinistroFcard">
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">LVIDd</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix smaller" subscriptSizing="dynamic">
                        <input matInput name="MModeVentricoloSinistroLvidd" placeholder="LVIDd" [(ngModel)]="referto.MModeVentricoloSinistroLvidd">
                        <span matSuffix class="inputSuffix">n.<=1.7</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">EPSS</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix" subscriptSizing="dynamic">
                        <input matInput name="MModeVentricoloSinistroEpss" placeholder="EPSS" [(ngModel)]="referto.MModeVentricoloSinistroEpss">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div class="flex-col gap12">
            <mat-label>B-Mode</mat-label>
            <div class="flex-row space-between-center" style=" max-width: calc(100vw - 280px)!important;">
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">Diam.</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix" subscriptSizing="dynamic">
                        <span matPrefix class="inputPrefix" i18n>Aorta</span>
                        <input matInput name="BModeDiamaorta"  [(ngModel)]="referto.BModeDiamaorta">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">Diam.</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix" subscriptSizing="dynamic">
                        <span matPrefix class="inputPrefix" i18n>Polmonare</span>
                        <input matInput name="BModeDiampolmonare"  [(ngModel)]="referto.BModeDiampolmonare">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">Ao</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix" subscriptSizing="dynamic">
                        <span matPrefix class="inputPrefix" i18n>Atrio&nbsp;sx/Ao</span>
                        <input matInput name="BModeAoatriosxao"  [(ngModel)]="referto.BModeAoatriosxao">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">Asx</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix" subscriptSizing="dynamic">
                        <span matPrefix class="inputPrefix" i18n>Atrio&nbsp;sx/Ao</span>
                        <input matInput name="BModeAsxatriosxao"  [(ngModel)]="referto.BModeAsxatriosxao">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">Asx/Ao</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix" subscriptSizing="dynamic">
                        <span matPrefix class="inputPrefix" i18n>Atrio&nbsp;sx/Ao</span>
                        <input matInput name="BModeAsxaoatriosxao"  [(ngModel)]="referto.BModeAsxaoatriosxao">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex min-width">
                    <mat-label class="labelWithSuffixAndBorders">LAD</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex inputWithSuffix" subscriptSizing="dynamic">
                        <span matPrefix class="inputPrefix" i18n>Atrio&nbsp;sx/Ao</span>
                        <input matInput name="BModeLadatriosxao"  [(ngModel)]="referto.BModeLadatriosxao">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>

        <div class="flex-col ">
            <div style="margin-bottom: 12px;">
                <mat-label>Doppler</mat-label>
            </div>
            <div class="flex-row fxFlex start-center wrapperLineaSezione" style="margin-top: 4px;" >
                <div class="flex-row start-center lineaSezione" >
                    <mat-label i18n>MITRALE</mat-label>
                </div>
                <div class="fxFlex" >
                    <span i18n>Inserire i valori relativi a questa sezione nei campi sottostanti</span>
                </div>
            </div>

            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">vel.p.E</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Dopplermitralevelpe" placeholder="vel.p.E" [(ngModel)]="referto.Dopplermitralevelpe">
                        <span matSuffix class="inputSuffix">m/s</span>
                    </mat-form-field>
                </div>
            </div>

            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">vel.p.A</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Dopplermitralevelpa" placeholder="vel.p.A" [(ngModel)]="referto.Dopplermitralevelpa">
                        <span matSuffix class="inputSuffix">m/s</span>
                    </mat-form-field>
                </div>
            </div>

            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix" i18n>rig. mitralico</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Dopplermitralerigmitralico" placeholder="rig. mitralico" i18n-placeholder [(ngModel)]="referto.Dopplermitralerigmitralico">
                        <span matSuffix class="inputSuffix">m/s</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row fxFlex start-center wrapperLineaSezione" style="margin-top: 4px;" >
                <div class="flex-row start-center lineaSezione" >
                    <mat-label i18n>AORTA</mat-label>
                </div>
                <div class="fxFlex" >
                    <span i18n>Inserire i valori relativi a questa sezione nei campi sottostanti</span>
                </div>
            </div>

            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">FVI LVOT</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Doppleraortafvilvot" placeholder="FVI LVOT" [(ngModel)]="referto.Doppleraortafvilvot">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">vel.p.Ao</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Doppleraortavelpao" placeholder="vel.p.Ao" [(ngModel)]="referto.Doppleraortavelpao">
                        <span matSuffix class="inputSuffix">m/s</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">grad.p.</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Doppleraortagradp" placeholder="grad.p." [(ngModel)]="referto.Doppleraortagradp">
                        <span matSuffix class="inputSuffix">mmHg</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row fxFlex start-center wrapperLineaSezione" style="margin-top: 4px;" >
                <div class="flex-row start-center lineaSezione" >
                    <mat-label i18n>TRICUSPIDE (TR)</mat-label>
                </div>
                <div class="fxFlex" >
                    <span i18n>Inserire i valori relativi a questa sezione nei campi sottostanti</span>
                </div>
            </div>
            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">TR rig</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Dopplertricuspidetrrig" placeholder="TR rig" [(ngModel)]="referto.Dopplertricuspidetrrig">
                        <span matSuffix class="inputSuffix">m/s</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">Grad.ITR</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Dopplertricuspidegraditr" placeholder="Grad.ITR" [(ngModel)]="referto.Dopplertricuspidegraditr">
                        <span matSuffix class="inputSuffix">mmHg</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row fxFlex start-center wrapperLineaSezione" style="margin-top: 4px;" >
                <div class="flex-row start-center lineaSezione" >
                    <mat-label i18n>POLMONARE</mat-label>
                </div>
                <div class="fxFlex" >
                    <span i18n>Inserire i valori relativi a questa sezione nei campi sottostanti</span>
                </div>
            </div>
            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">FVI RVOT</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Dopplerpolmonarefvirvot" placeholder="FVI RVOT" [(ngModel)]="referto.Dopplerpolmonarefvirvot">
                        <span matSuffix class="inputSuffix">cm</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">vel.p.P</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Dopplerpolmonarevelpp" placeholder="vel.p.P" [(ngModel)]="referto.Dopplerpolmonarevelpp">
                        <span matSuffix class="inputSuffix">ms</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row fxFlex" style="margin-top: 2px;">
                <div class="flex-row start-center labelWithBorders">
                    <mat-label class="labelInputWithSuffix">grad.p.</mat-label>
                </div>
                <div class="fxFlex">
                    <mat-form-field class="fxFlex inputWithSuffix" appearance="outline" subscriptSizing="dynamic">
                        <input matInput name="Dopplerpolmonaregradp" placeholder="grad.p." [(ngModel)]="referto.Dopplerpolmonaregradp">
                        <span matSuffix class="inputSuffix">mmHg</span>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- // Diagnosi e terapia -->
        <div class="flex-col gap12">
            <mat-divider></mat-divider>
            <span class="miniTitle" i18n>Diagnosi e Terapia</span>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch ">
                <mat-label i18n>Diagnosi</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <textarea matInput name="Diagnosi" placeholder="Diagnosi"
                        [(ngModel)]="referto.Diagnosi"></textarea>
                </mat-form-field>
            </div>
            <div class="flex-col start-stretch ">
                <mat-label i18n>Terapia</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <textarea matInput name="Terapia" placeholder="Terapia" [(ngModel)]="referto.Terapia"></textarea>
                </mat-form-field>
            </div>
        </div>


        <mat-divider></mat-divider>
        <div class="flex-col start-stretch gap12">
            <mat-label i18n>Referto</mat-label>
            <span class="valoriDefault">{{defReferto}}</span>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                <textarea matInput name="Referto" placeholder="Referto" rows="10" i18n-placeholder
                    [(ngModel)]="referto.Referto"></textarea>
            </mat-form-field>
        </div>
    </form>
</fieldset>