<fieldset [disabled]="isCliente" *ngIf="referto != undefined && (!isCliente || isCliente && referto.Disponibile)"
    style="border: none;padding: 0px!important;">

    <div style="visibility: hidden;">{{hasForm}}</div>
    <form #f="ngForm" class="flex-col gap12 start-stretch" style="height: calc(100vh - 380px);overflow: auto;">
        <div class="flex-col gap12">
            <mat-divider></mat-divider>
            <span class="miniTitle" i18n>Considerazioni preliminari</span>
            <mat-divider></mat-divider>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <mat-label i18n>Motivo dell'esame</mat-label>
                <textarea matInput name="MotivoEsame" placeholder="motivo esame" i18n-placeholder
                    [(ngModel)]="referto.MotivoEsame"></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <mat-label i18n>Anamnesi</mat-label>
                <textarea matInput name="Anamnesi" placeholder="Anamnesi" [(ngModel)]="referto.Anamnesi" i18n-placeholder ></textarea>
            </mat-form-field>
        </div>
        <div class="flex-col gap12">
            <mat-divider></mat-divider>
            <span class="miniTitle" i18n>RADIOGRAFIA</span>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <span i18n>Radiografia del torace </span>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic" style="margin-bottom: 12px;">
                    <textarea matInput name="RadiografiaTorace" placeholder="Radiografia del torace" i18n-placeholder
                        [(ngModel)]="referto.RadiografiaTorace"></textarea>
                </mat-form-field>
                <mat-divider style="margin-bottom: 12px;"></mat-divider>
                <span i18n>Radiografia dell'addome </span>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <textarea matInput name="RadiografiaAddome" placeholder="Radiografia dell'addome" i18n-placeholder
                        [(ngModel)]="referto.RadiografiaAddome"></textarea>
                </mat-form-field>
            </div>
        </div>
        <mat-divider style="margin-bottom: 12px;"></mat-divider>
        <div class="flex-col start-stretch gap12">
            <mat-label i18n>Referto</mat-label>
            <span class="valoriDefault">{{defReferto}}</span>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                <textarea matInput name="Referto" placeholder="Referto" rows="10" i18n-placeholder
                    [(ngModel)]="referto.Referto"></textarea>
            </mat-form-field>
        </div>
    </form>


</fieldset>