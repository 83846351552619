<fieldset [disabled]="isCliente" *ngIf="referto != undefined && (!isCliente || (isCliente && referto.Disponibile))"
    style="border: none!important;padding: 0px!important;">
    <!-- GETTER PER VALUTARE STATO FORM -->
    <div style="visibility: hidden;">{{hasForm}}</div>
    <!-- GETTER PER VALUTARE STATO FORM -->
    <form #f="ngForm" class="flex-col gap12 start-stretch" style="height: calc(100vh - 386px);overflow: auto">
        <div class="flex-col gap12">
            <mat-divider></mat-divider>
            <span class="miniTitle" i18n>Considerazioni preliminari</span>
            <mat-divider></mat-divider>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <mat-label i18n>Motivo dell'esame</mat-label>
                <textarea matInput name="MotivoEsame" placeholder="motivo esame" i18n-placeholder
                    [(ngModel)]="referto.MotivoEsame"></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <mat-label i18n>Anamnesi</mat-label>
                <textarea matInput name="Anamnesi" placeholder="Anamnesi" i18n-placeholder [(ngModel)]="referto.Anamnesi"></textarea>
            </mat-form-field>
        </div>
        <div class="flex-col gap12">
            <mat-divider></mat-divider>
            <span class="miniTitle">HOLTER</span>
            <mat-divider></mat-divider>
            <span i18n>Generale</span>
            <div class="flex-row space-between-center max-width">
                <!-- col1 -->
                <div class="flex-col center-center inputFieldWrapper fxFlex">
                    <span i18n>Ora inizio</span>
                    <input class="miniInput" name="OraInizio" [(ngModel)]="referto.OraInizio">
                </div>
                <!-- col2 -->
                <div class="flex-col center-center inputFieldWrapper fxFlex">
                    <span i18n>Battiti totali</span>
                    <input class="miniInput" name="BattitiTotali" [(ngModel)]="referto.BattitiTotali">
                </div>
                <!-- col3 -->
                <div class="flex-col center-center inputFieldWrapper fxFlex">
                    <span i18n>Artefatti</span>
                    <input class="miniInput" name="Artefatti" [(ngModel)]="referto.Artefatti">
                </div>
                <!-- col4 -->
                <div class="flex-col center-center inputFieldWrapper fxFlex">
                    <span i18n>Ore analizzate</span>
                    <input class="miniInput" name="OreAnalizzate" [(ngModel)]="referto.OreAnalizzate">
                </div>
                <!-- col5 -->
                <div class="flex-col center-center inputFieldWrapper fxFlex">
                    <span i18n>Battiti sconosciuti</span>
                    <input class="miniInput" name="BattitiSconosiuti" [(ngModel)]="referto.BattitiSconosiuti">
                </div>
                <!-- col6 -->
                <div class="flex-col center-center inputFieldWrapper fxFlex">
                    <span i18n>Qualità registrazione</span>
                    <input class="miniInput" name="QualitaRegistrazione" [(ngModel)]="referto.QualitaRegistrazione">
                </div>
            </div>
            <mat-divider></mat-divider>
            <span i18n>Eventi Frequenza Dipendenti</span>
            <div class="flex-row space-between-center max-width">
                <!-- col1 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Frequenza minima</span>
                    <input class="miniInputSmall" name="FrequenzaMinima" [(ngModel)]="referto.FrequenzaMinima">
                </div>
                <!-- col2 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Alle ore</span>
                    <input class="miniInputSmall" name="FrequenzaMinimaAlleOre" [(ngModel)]="referto.FrequenzaMinimaAlleOre">
                </div>
                <!-- col3 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Frequenza massima</span>
                    <input class="miniInputSmall" name="FrequenzaMassima" [(ngModel)]="referto.FrequenzaMassima">
                </div>
                <!-- col4 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Alle ore</span>
                    <input class="miniInputSmall" name="FrequenzaMassimaAlleOre" [(ngModel)]="referto.FrequenzaMassimaAlleOre">
                </div>
                <!-- col5 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Frequenza media</span>
                    <input class="miniInputSmall" name="FrequenzaMedia" [(ngModel)]="referto.FrequenzaMedia">
                </div>
                <!-- col6 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Salve bradicardia</span>
                    <input class="miniInputSmall" name="SalveBradicardia" [(ngModel)]="referto.SalveBradicardia">
                </div>
                <!-- col7 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Più lunga</span>
                    <input class="miniInputSmall" name="PiuLunga" [(ngModel)]="referto.PiuLunga">
                </div>
                <!-- col8 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Frequenza minima</span>
                    <input class="miniInputSmall" name="FrequenzaMinima2" [(ngModel)]="referto.FrequenzaMinima2">
                </div>
                <!-- col9 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Pause > 3 sec.</span>
                    <input class="miniInputSmall" name="PauseMaggiori3Sec" [(ngModel)]="referto.PauseMaggiori3Sec">
                </div>
                <!-- col10 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Più lunga</span>
                    <input class="miniInputSmall" name="PiuLunga2" [(ngModel)]="referto.PiuLunga2">
                </div>
                <!-- col11 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Alle ore</span>
                    <input class="miniInputSmall" name="AlleOre" [(ngModel)]="referto.AlleOre">
                </div>
            </div>
            <mat-divider></mat-divider>
            <span i18n>Eventi ventricolari </span>
            <div class="flex-row space-between-center max-width">
                <!-- col1 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Battiti totali</span>
                    <input class="miniInputSmall" name="VentricolariBattitiTotali" [(ngModel)]="referto.VentricolariBattitiTotali">
                </div>
                <!-- col2 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Coppie</span>
                    <input class="miniInputSmall" name="VentricolariCoppie" [(ngModel)]="referto.VentricolariCoppie">
                </div>
                <!-- col3 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Morfologia prevalente</span>
                    <input class="miniInputSmall" name="VentricolariMorfologiaPrevalente" [(ngModel)]="referto.VentricolariMorfologiaPrevalente">
                </div>
                <!-- col4 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>% battiti sul totale</span>
                    <input class="miniInputSmall" name="VentricolariPercentualeBattitiSulTotale" [(ngModel)]="referto.VentricolariPercentualeBattitiSulTotale">
                </div>
                <!-- col5 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Triplette</span>
                    <input class="miniInputSmall" name="VentricolariTriplette" [(ngModel)]="referto.VentricolariTriplette">
                </div>
                <!-- col6 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Forme</span>
                    <input class="miniInputSmall" name="VentricolariForme" [(ngModel)]="referto.VentricolariForme">
                </div>
                <!-- col7 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Bigemine</span>
                    <input class="miniInputSmall" name="VentricolariBigemine" [(ngModel)]="referto.VentricolariBigemine">
                </div>
                <!-- col8 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Salve AIVR/IVR</span>
                    <input class="miniInputSmall" name="VentricolariSalveAivrIvr" [(ngModel)]="referto.VentricolariSalveAivrIvr">
                </div>
                <!-- col9 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Più lunga</span>
                    <input class="miniInputSmall" name="VentricolariPiuLunga" [(ngModel)]="referto.VentricolariPiuLunga">
                </div>
                <!-- col10 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Frequenza minima</span>
                    <input class="miniInputSmall" name="VentricolariFrequenzaMinima" [(ngModel)]="referto.VentricolariFrequenzaMinima">
                </div>
                <!-- col11 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Salve tachicardia V</span>
                    <input class="miniInputSmall" name="VentricolariSalveTachicardiaV" [(ngModel)]="referto.VentricolariSalveTachicardiaV">
                </div>
                <!-- col12 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Più lunga</span>
                    <input class="miniInputSmall" name="VentricolariPiuLunga2" [(ngModel)]="referto.VentricolariPiuLunga2">
                </div>
                <!-- col13 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Frequenza massima</span>
                    <input class="miniInputSmall" name="VentricolariFrequenzaMassima" [(ngModel)]="referto.VentricolariFrequenzaMassima">
                </div>
                <!-- col14 -->
                <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                    <span i18n>Correl. con freq. card. sin.</span>
                    <input class="miniInputSmall" name="VentricolariCorrelFreqCardSin" [(ngModel)]="referto.VentricolariCorrelFreqCardSin">
                </div>
            </div>
        </div>
        <mat-divider>   </mat-divider>
        <span i18n>Eventi Sopraventricolari</span>
        <div class="flex-row space-between-center max-width">
            <!-- col1 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>Battiti totali</span>
                <input class="miniInputSmall" name="SopraventicolariBattitiTotali" [(ngModel)]="referto.SopraventricolariBattitiTotali">
            </div>
            <!-- col2 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>Coppie</span>
                <input class="miniInputSmall" name="SopraventicolariCoppie" [(ngModel)]="referto.SopraventricolariCoppie">
            </div>
            <!-- col3 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>% battiti sul totale</span>
                <input class="miniInputSmall" name="SopraventicolariCoppie" [(ngModel)]="referto.SopraventricolariPercentualeBattitiSulTotale">
            </div>
            <!-- col4 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>% prematurità</span>
                <input class="miniInputSmall" name="SopraventicolariPercentualePrematurita" [(ngModel)]="referto.SopraventricolariPercentualePrematurita">
            </div>
            <!-- col5 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>Salve tachicardia SV</span>
                <input class="miniInputSmall" name="SopraventicolariSalveTachicardiaSv" [(ngModel)]="referto.SopraventricolariSalveTachicardiaSv">
            </div>
            <!-- col6 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>Più lunga</span>
                <input class="miniInputSmall" name="SopraventicolariPiuLunga" [(ngModel)]="referto.SopraventricolariPiuLunga">
            </div>
            <!-- col7 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>Frequenza massima</span>
                <input class="miniInputSmall" name="SopraventicolariFrequenzaMassima" [(ngModel)]="referto.SopraventricolariFrequenzaMassima">
            </div>
            <!-- col8 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>SVe/minuto max</span>
                <input class="miniInputSmall" name="SopraventricolariSveminutomax" [(ngModel)]="referto.SopraventricolariSveminutomax">
            </div>
            <!-- col9 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>SVe/ora max</span>
                <input class="miniInputSmall" name="SopraventricolariSVeOraMax" [(ngModel)]="referto.SopraventricolariSVeOraMax">
            </div>
            <!-- col10 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>SVe/ora medio</span>
                <input class="miniInputSmall" name="SopraventricolariSVeOraMedio" [(ngModel)]="referto.SopraventricolariSVeOraMedio">
            </div>
            <!-- col10 -->
            <div class="flex-col space-between-center inputFieldWrapper fxFlex">
                <span i18n>SVe/1000</span>
                <input class="miniInputSmall" name="SopraventricolariSVeOraMax" [(ngModel)]="referto.SopraventricolariSVe1000">
            </div>
        </div>
        <mat-divider>   </mat-divider>
        <span i18n>Note</span>
        <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
            <mat-label i18n>Note</mat-label>
            <textarea matInput name="Note" placeholder="Note" i18n-placeholder [(ngModel)]="referto.Note"></textarea>
        </mat-form-field>
        <mat-divider></mat-divider>
        <span i18n>Conclusioni</span>
        <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
            <mat-label i18n>Conclusioni</mat-label>
            <textarea matInput name="Conclusioni" placeholder="Conclusioni" i18n-placeholder [(ngModel)]="referto.Conclusioni"></textarea>
        </mat-form-field>
        <mat-divider></mat-divider>
        <div class="flex-col start-stretch gap12">
            <mat-label i18n>Referto</mat-label>
            <span class="valoriDefault">{{defReferto}}</span>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                <textarea matInput name="Referto" placeholder="Referto" rows="10" i18n-placeholder
                    [(ngModel)]="referto.Referto"></textarea>
            </mat-form-field>
        </div>
    </form>