<div class="fxFlex flex-col" style="overflow: hidden!important;">
    <mat-toolbar class="internal-toolbar" style="position: sticky;" >
        <div class="flex-row space-between-center fxFlex">
            <div class="flex-row start-center">
                <button mat-icon-button [routerLink]="'../'" color="accent" *ngIf="!modeInput">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <span class="miniTitle">Paziente</span>
                <button mat-icon-button (click)="deletePaziente()" color="warn" matTooltip="Elimina Paziente" *ngIf="!modeInput" i18n-matTooltip>
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <div *ngIf="paziente && paziente.User && !modeInput" style="font-size: small;">
                <span i18n>Inserito da: </span>
                <span>{{paziente.User.Nome + " " + paziente.User.Cognome}}</span>
            </div>

            <button mat-raised-button color="accent" [disabled]="!form.valid" [tabIndex]="8" class="saveBtn" (click)="salvaPaziente()" i18n>SALVA</button>
        </div>
    </mat-toolbar>
    <form #form="ngForm" class="fxFlex flex-col" >
        <div class="flex-row space-between-start " style="overflow: auto;height: calc(100vh - 148px);" *ngIf="paziente">
            <div class="flex-col" style="padding: 24px; width: 45%;">
                <!-- Nome -->
                <div class="flex-row start-center fxFlex gap24">
                    <mat-label style="width: 20%;" i18n>Nome</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <input matInput name="nome"  [(ngModel)]="paziente.Nome" required placeholder="Nome">
                    </mat-form-field>
                </div>
                <!-- Proprietario -->
                <div class="flex-row start-center fxFlex gap24">
                    <mat-label style="width: 20%;" i18n>Proprietario</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <input matInput name="cp"  [(ngModel)]="paziente.CognomeProprietario" 
                            placeholder="Proprietario">
                    </mat-form-field>
                </div>
                <!-- Specie -->
                <div class="flex-row start-center fxFlex gap24">
                    <mat-label style="width: 20%;" i18n>Specie</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <input matInput name="specie"  type="text" [(ngModel)]="paziente.Specie" required placeholder="Specie">
                    </mat-form-field>
                </div>
                <!-- Razza -->
                <div class="flex-row start-center fxFlex gap24">
                    <mat-label style="width: 20%;" i18n>Razza</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <input matInput name="Razza"  type="text" [(ngModel)]="paziente.Razza"  placeholder="Razza">
                    </mat-form-field>
                </div>
                <!-- Sesso -->
                <div class="flex-row start-center fxFlex gap24">
                    <mat-label style="width: 20%;" i18n>Sesso</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <mat-select name="Sesso"  [(ngModel)]="paziente.Sesso" required>
                            <mat-option value="M">Maschio</mat-option>
                            <mat-option value="F">Femmina</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- Età -->
                <div class="flex-row start-center fxFlex gap24">
                    <mat-label style="width: 20%;" i18n>Età</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <input matInput name="Eta"  type="number" [(ngModel)]="paziente.Eta"  placeholder="Età">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-col" style="padding: 24px; width: 45%;">
                <!-- Peso -->
                <div class="flex-row start-center fxFlex gap24">
                    <mat-label style="width: 20%;" i18n>Peso</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <input matInput name="Peso"  type="number" [(ngModel)]="paziente.Peso"  placeholder="Peso">
                    </mat-form-field>
                </div>
                <!-- Numero Chip -->
                <div class="flex-row start-center fxFlex gap24">
                    <mat-label style="width: 20%;" i18n>Numero Chip</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <input matInput name="NChip"  [(ngModel)]="paziente.NChip" required placeholder="Numero Chip">
                    </mat-form-field>
                </div>
                <!-- Storia clinica -->
                <div class="flex-row start-center fxFlex gap24">
                    <mat-label style="width: 20%;" i18n>Storia Clinica</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <textarea matInput name="sclinica" [(ngModel)]="paziente.StoriaClinica"
                            placeholder="Storia Clinica"></textarea>
                    </mat-form-field>
                </div>
                <!-- Note -->
                <div class="flex-row start-center fxFlex gap24">
                    <mat-label style="width: 20%;" i18n>Note</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <textarea matInput name="note" [(ngModel)]="paziente.Note" placeholder="Note"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</div>