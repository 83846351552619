<fieldset [disabled]="isCliente" *ngIf="referto != undefined && (!isCliente || (isCliente && referto.Disponibile))" 
style="border: none!important;padding: 0px!important;">
    <!-- GETTER PER VALUTARE STATO FORM -->
    <div style="visibility: hidden;">{{hasForm}}</div>
    <!-- GETTER PER VALUTARE STATO FORM -->

    <form #f="ngForm" class="flex-col gap12 start-stretch" style="height: calc(100vh - 386px);overflow: auto" >
        <div class="flex-col gap12">
            <mat-divider></mat-divider>
            <span class="miniTitle" i18n>Considerazioni preliminari</span>
            <mat-divider></mat-divider>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <mat-label i18n>Motivo dell'esame</mat-label>
                <textarea matInput name="MotivoEsame" placeholder="motivo esame" i18n-placeholder
                    [(ngModel)]="referto.MotivoEsame"></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <mat-label i18n>Anamnesi</mat-label>
                <textarea matInput name="Anamnesi" placeholder="Anamnesi" i18n-placeholder [(ngModel)]="referto.Anamnesi"></textarea>
            </mat-form-field>
            <div class="flex-col gap12" style="margin-top: 12px;">
                <span class="miniTitle" i18n>Considerazioni generali</span>
                <mat-divider></mat-divider>
                <div class="flex-col gap12">
                    <span i18n>Esame ecografico addominale</span>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                        <textarea matInput name="EsameEcograficoAddominale" placeholder="Esame ecografico addominale" i18n-placeholder
                            [(ngModel)]="referto.EsameEcograficoAddominale"></textarea>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="flex-col gap12">
                    <span i18n>Fegato</span>
                    <span class="valoriDefault">{{defFegato}}</span>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                        <textarea matInput name="Fegato" placeholder="Fegato" i18n-placeholder
                            [(ngModel)]="referto.Fegato"></textarea>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="flex-col gap12">
                    <span i18n>Milza</span>
                    <span class="valoriDefault">{{defMilza}}</span>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                        <textarea matInput name="Milza" placeholder="Milza" i18n-placeholder
                            [(ngModel)]="referto.Milza"></textarea>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="flex-col gap12">
                    <span i18n>Apparato gastroenterico e pancreas</span>
                    <span class="valoriDefault">{{defApparatoGastrointericoEPancreas}}</span>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                        <textarea matInput name="ApparatoGastrointericoEPancreas" placeholder="Apparato gastroenterico e pancreas"
                            [(ngModel)]="referto.ApparatoGastrointericoEPancreas" i18n-placeholder ></textarea>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="flex-col gap12">
                    <span i18n>Linfonodi</span>
                    <span class="valoriDefault">{{defLinfnodi}}</span>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                        <textarea matInput name="Linfonodi" placeholder="Linfonodi" i18n-placeholder
                            [(ngModel)]="referto.Linfonodi"></textarea>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="flex-col gap12">
                    <span i18n>Reni</span>
                    <span class="valoriDefault">{{defReni}}</span>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                        <textarea matInput name="Reni" placeholder="Reni" i18n-placeholder
                            [(ngModel)]="referto.Reni"></textarea>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="flex-col gap12">
                    <span i18n>Surreni</span>
                    <span class="valoriDefault">{{defSurreni}}</span>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                        <textarea matInput name="Surreni" placeholder="Surreni" i18n-placeholder 
                            [(ngModel)]="referto.Surreni"></textarea>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="flex-col gap12">
                    <span i18n>Vescica</span>
                    <span class="valoriDefault">{{defVescica}}</span>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                        <textarea matInput name="Vescica" placeholder="Vescica" i18n-placeholder
                            [(ngModel)]="referto.Vescica"></textarea>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="flex-col gap12">
                    <span i18n>Apparato genitale</span>
                    <span class="valoriDefault">{{defApparatoGenitale}}</span>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                        <textarea matInput name="ApparatoGenitale" placeholder="Apparato genitale" i18n-placeholder
                            [(ngModel)]="referto.ApparatoGenitale"></textarea>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="flex-col gap12">
                    <span i18n>Altro</span>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                        <textarea matInput name="Altro" placeholder="Altro" i18n-placeholder
                            [(ngModel)]="referto.Altro"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex-col start-stretch gap12">
            <mat-label i18n>Referto</mat-label>
            <span class="valoriDefault">{{defReferto}}</span>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                <textarea matInput name="Referto" placeholder="Referto" rows="10" i18n-placeholder
                    [(ngModel)]="referto.Referto"></textarea>
            </mat-form-field>
        </div>
    </form>
</fieldset>