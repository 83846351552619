import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import localeit from '@angular/common/locales/it';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoginComponent } from './components/login/login.component';
import { ModificaPasswordComponent } from './components/modifica-password/modifica-password.component';
import { RecuperaPasswordComponent } from './components/recupera-password/recupera-password.component';
import { MaterialModule } from './shared/modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { AauDateAdapter } from './shared/date-adapter';
import { JwtAuthInterceptor } from './interceptors/jwt.interceptor';
import { registerLocaleData } from '@angular/common';
import { LandingComponent } from './components/landing/landing.component';
import { MenuComponent } from './components/menu/menu.component';
import { UserListaComponent } from './components/users/user-lista/user-lista.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { PazientiListaComponent } from './components/pazienti/pazienti-lista/pazienti-lista.component';
import { PazientiDetailComponent } from './components/pazienti/pazienti-detail/pazienti-detail.component';
import { IsAuthDirective } from './directives/is-auth.directive';
import { CalendarioMainComponent } from './components/calendario/calendario-main/calendario-main.component';
import { ConsultoDetailComponent } from './components/consulti/consulto-detail/consulto-detail.component';
import { ConsultiListComponent } from './components/consulti/consulti-list/consulti-list.component';
import { ListiniComponent } from './components/listini/listini.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { PagamentoComponent } from './components/pagamento/pagamento.component';
import { ListaSlotComponent } from './components/lista-slot/lista-slot.component';
import { AllegatiComponent } from './components/allegati/allegati.component';
import { EcgComponent } from './components/referti/ecg/ecg.component';
import { EcocardiografiaComponent } from './components/referti/ecocardiografia/ecocardiografia.component';
import { EcografiaComponent } from './components/referti/ecografia/ecografia.component';
import { HolterComponent } from './components/referti/holter/holter.component';
import { RadiografiaComponent } from './components/referti/radiografia/radiografia.component';
import { SmallpartsComponent } from './components/referti/smallparts/smallparts.component';
import { RefertoComponent } from './components/referti/referto/referto.component';
import { FilePreviewComponent } from './shared/components/file-preview/file-preview.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { FatturaDetailComponent } from './components/fatture/fattura-detail/fattura-detail.component';
import { TeleconferenzaComponent } from './components/teleconferenza/teleconferenza.component';
import { VideoCallComponent } from './components/teleconferenza/video-call/video-call.component';
import { InfoTeleconferenzaComponent } from './components/teleconferenza/info-teleconferenza/info-teleconferenza.component';
import { LoadingDialogComponent } from './shared/components/loading-dialog/loading-dialog.component';
import { I18nSwitcherComponent } from './shared/components/i18n-switcher/i18n-switcher.component';
import { FattureListaComponent } from './components/fatture-lista/fatture-lista.component';
import { StatoFatturaComponent } from './components/shared/stato-fattura/stato-fattura.component';
import { ListaMailNotificheComponent } from './components/lista-mail-notifiche/lista-mail-notifiche.component';
import { LandighomeComponent } from './components/landighome/landighome.component';

registerLocaleData(localeit, 'it');
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ModificaPasswordComponent,
    RecuperaPasswordComponent,
    LandingComponent,
    MenuComponent,
    UserListaComponent,
    UserDetailComponent,
    ConfirmDialogComponent,
    PazientiListaComponent,
    PazientiDetailComponent,
    IsAuthDirective,
    CalendarioMainComponent,
    ConsultoDetailComponent,
    ConsultiListComponent,
    ListiniComponent,
    PagamentoComponent,
    ListaSlotComponent,
    AllegatiComponent,
    EcgComponent,
    EcocardiografiaComponent,
    EcografiaComponent,
    HolterComponent,
    RadiografiaComponent,
    SmallpartsComponent,
    RefertoComponent,
    FilePreviewComponent,
    FatturaDetailComponent,
    TeleconferenzaComponent,
    VideoCallComponent,
    InfoTeleconferenzaComponent,
    LoadingDialogComponent,
    I18nSwitcherComponent,
    FattureListaComponent,
    StatoFatturaComponent,
    ListaMailNotificheComponent,
    LandighomeComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPayPalModule,
    MaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
      },
    { provide: HTTP_INTERCEPTORS, useClass: JwtAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: DateAdapter, useClass: AauDateAdapter },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
