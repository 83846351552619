import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Observable,
  Subscription,
  catchError,
  forkJoin,
  lastValueFrom,
  of,
} from 'rxjs';
import { Allegato } from 'src/app/model/allegato.model';
import { Consulto } from 'src/app/model/consulto.model';
import {
  hasFattura,
  Pagamento,
  pagamentoEffettuato,
  pagamentoSospeso,
} from 'src/app/model/pagamento.model';
import { Paziente } from 'src/app/model/paziente.model';
import { Slot } from 'src/app/model/slot.model';
import { TipoConsulto } from 'src/app/model/tipo-consulto.model';
import { ruoloAdmin, ruoloCliente, User } from 'src/app/model/user.model';
import { ConsultoRefertoCoordinatorService } from 'src/app/services/consulto-referto-coordinator.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import {
  MessageConstants,
  MessageData,
  MessageService,
} from 'src/app/services/message.service';
import { ApiUrl } from 'src/app/shared/api-url';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { pagamentoPaypal } from 'src/app/shared/generic-constant';
import { datiPagamentoPaypal } from '../../pagamento/pagamento.component';
import { ConsultoUnsavedDataService } from 'src/app/services/consulto-unsaved-data.service';
import { PazientiDetailComponent } from '../../pazienti/pazienti-detail/pazienti-detail.component';

@Component({
  selector: 'app-consulto-detail',
  templateUrl: './consulto-detail.component.html',
  styleUrls: ['./consulto-detail.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class ConsultoDetailComponent implements OnInit, AfterViewInit {
  constructor(
    private currentUser: CurrentUserService,
    private http: HttpClient,
    private actRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private dialog: MatDialog,
    private consultoRefertoCoordinator: ConsultoRefertoCoordinatorService,
    private modifiche: ConsultoUnsavedDataService,
    private cdr: ChangeDetectorRef
  ) {
    this.consultoRefertoCoordinator.isFormTouched.subscribe((touched) => {
      this.secondStepProssimoDisabled = touched;
    });
    this.consultoRefertoCoordinator.isFormSaved.subscribe((saved) => {
      this.secondStepSaved = saved;
    });
    this.modifiche.modificheNonSalvate.subscribe((modifiche) => {
      this.unsavedChanges = modifiche;
    });
  }

  get linearStepper(): boolean {
    return this.currentUser.currentUser?.Ruolo == ruoloCliente;
  }

  secondStepProssimoDisabled: boolean = false;
  secondStepSaved: boolean = false;
  saveDisabled: boolean = true;

  consulto!: Consulto;
  slotAssociato: Slot | undefined;

  protected utenti: User[] = [];
  protected utenteSelezionato: User | undefined;
  protected utenteSelezionatoEmail: string | undefined;
  protected pazienti: Paziente[] = [];
  protected tipiConsulto: TipoConsulto[] = [];

  get firstStepCompleted(): boolean {
    let c: boolean = false;
    if (
      this.consulto != undefined &&
      this.consulto.PazienteID != undefined &&
      this.consulto.TipoConsultoID != undefined
    ) {
      c = true;
    }
    return c;
  }

  get isAfterToday(): boolean {
    if (this.consulto.Slot == undefined) {
      return false;
    }
    let d = new Date(this.consulto.Slot.DataInizio);
    let today = new Date();
    return d >= today;
  }

  /**
    LISTA TEMPLATE PER IL SECONDO STEP
  */

  @ViewChild('form') form!: NgForm;
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('ecg') ecg!: TemplateRef<any>;
  @ViewChild('ecocardiografia') ecocardiografia!: TemplateRef<any>;
  @ViewChild('ecografia') ecografia!: TemplateRef<any>;
  @ViewChild('holter') holter!: TemplateRef<any>;
  @ViewChild('radiografia') radiografia!: TemplateRef<any>;
  @ViewChild('smallParts') smallParts!: TemplateRef<any>;

  @ViewChild('dettaglioClientePaziente')
  dettaglioClientePaziente!: TemplateRef<any>;

  isNew: boolean = true;
  ngOnInit(): void {
    if (this.currentUser.currentUser == undefined) {
      return;
    }
    this.controllaRuoloERecuperaPazienti();
    this.recuperaUtenti();
    this.recuperaTipiConsulto();
    //this.checkMode();
  }

  private controllaRuoloERecuperaPazienti(IdToSelect?: number) {
    if (this.currentUser.currentUser == undefined) {
      return;
    }
    if (this.currentUser.currentUser?.Ruolo == ruoloAdmin) {
      this.recuperaPazienti(undefined, IdToSelect);
    } else {
      this.recuperaPazienti(this.currentUser.currentUser.Email);
    }
  }

  ngAfterViewInit(): void {
    this.checkMode();
    if (this.form != undefined) {
      this.formChangesListener();
    } else {
      setTimeout(() => {
        this.formChangesListener();
      }, 1000);
    }
  }

  unsavedChanges: boolean = false;
  sub: Subscription = new Subscription();
  formChangesListener() {
    this.sub.add(
      this.form.valueChanges?.subscribe((_) => {
        this.unsavedChanges = true;
      })
    );
  }

  async accettaAppuntamento() {
    if (this.consulto == undefined || this.consulto.Slot == undefined) {
      return;
    }
    if (await lastValueFrom(this.askConfirmPerAppuntamento(true))) {
      this.slotAssociato!.Accettato = true;
      this.consulto.Slot.Accettato = true;
      this.modifica(false);
    }
  }
  async rifiutaAppuntamento() {
    if (this.consulto == undefined || this.consulto.Slot == undefined) {
      return;
    }
    if (await lastValueFrom(this.askConfirmPerAppuntamento(false))) {
      this.slotAssociato!.Accettato = false;
      this.consulto.Slot.Accettato = false;
      this.modifica(true);
    }
  }

  askConfirmPerAppuntamento(accettato: boolean) {
    let data: ConfirmDialogData = { message: [] };

    if (accettato) {
      data.message.push(
        "Sei sicuro di voler accettare l'appuntamento? Il cliente riceverà una email di conferma."
      );
    } else {
      data.message.push(
        `Sei sicuro di voler rifiutare l\'appuntamento? Se accetti, la disponibilità per il giorno e l'orario verrà ripristinata.`
      );
      data.message.push(
        "L'utente riceverà una email che lo informerà della mancata accettazione dell'appuntamento."
      );
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: data });
    return dialogRef.afterClosed();
  }

  isModeDialog: boolean = false;
  tornaAlCalendarioAlGiorno: Date | undefined;

  checkMode() {
    // ROUTING MODE (NO TELECONFERENZA)
    this.actRoute.params.subscribe((params) => {
      if (params['id'] == 'new') {
        this.isNew = true;
        // In history state c'è lo slot selezionato e l'eventuale data del calendario alla quale dobbiamo tornare
        this.slotAssociato = history.state.slot;
        this.tornaAlCalendarioAlGiorno = history.state.dataInizioSettimana;
        // Se il consulto lo sta inserendo un admin, l'utente lo settiamo come undefined per costringere la scelta del cliente reale
        // altrimenti lo settiamo come l'utente corrente
        // lo slot invece dipende dallo stato di history.state.slot
        this.consulto = {
          UserEmail:
            this.currentUser.currentUser?.Ruolo == ruoloCliente
              ? this.currentUser.currentUser.Email
              : undefined,
          User:
            this.currentUser.currentUser?.Ruolo == ruoloCliente
              ? this.currentUser.currentUser
              : undefined,
          Slot:
            history.state.dataInizioSettimana != undefined && history.state.slot
              ? this.slotAssociato
              : undefined,
        } as Consulto;
      } else {
        this.isNew = false;
        if (history.state.navigateToTab != undefined) {
          // Se stiamo navigando da calendario, per un consulto già esistente
          // Voglio navigare alla step teleconferenza di stepper.
          // In generale si può aggiungere ad history.state un campo per sapere a quale step navigare
          console.log(history.state);
          const tabToNavigate = history.state.navigateToTab;
          this.tornaAlCalendarioAlGiorno = history.state.dataInizioSettimana;
          console.log(tabToNavigate);
          if (this.stepper != undefined) {
            this.stepper.selectedIndex = tabToNavigate;
          }
        }
        this.recuperaConsulto(params['id']);
      }
      this.cdr.detectChanges();
    });
  }

  pazienteChanged(ev: number) {
    this.consulto.PazienteID = ev;
    let p = this.pazienti.find((p) => p.Id == ev);
    if (p != undefined) {
      this.consulto.Paziente = p;
      this.consulto.UserEmail = p.UserEmail;
      this.utenteSelezionatoEmail = p.UserEmail;
      this.utenteSelezionato = this.utenti.find((u) => u.Email == p!.UserEmail);
    }
  }

  utenteChanged(ev: string) {
    let email: string = ev;
    this.utenteSelezionato = this.utenti.find((u) => u.Email == email);
    if (this.utenteSelezionato == undefined) {
      return console.log("Errore nella selezione dell'utente");
    }
    this.consulto.UserEmail = this.utenteSelezionato.Email;
  }

  private recuperaConsulto(id: string) {
    const url = ApiUrl.CONSULTI + '/' + id;
    const obs = this.http.get<Consulto>(url);
    obs.subscribe(async (consulto) => {
      if (consulto.Allegati != undefined && consulto.Allegati.length > 0) {
        for (let i = 0; i < consulto.Allegati!.length; i++) {
          const element = consulto.Allegati![i];
          const allegatoMinio = await lastValueFrom(
            this.recuperaUrlDownload(element.AwsID!)
          );
          if (allegatoMinio.AwsUrl != undefined) {
            const fileOBS = this.http.get(allegatoMinio.AwsUrl, {
              responseType: 'blob',
            });
            fileOBS.subscribe((file) => {
              consulto.Allegati![i].File = new File([file], element.Nome, {
                type: file.type,
              });
            });
          }
        }
      }
      this.consulto = consulto;
      if (consulto.Slot != undefined) {
        this.recuperaSlotAssociato();
      }
    });
  }

  private recuperaSlotAssociato() {
    const url = ApiUrl.SLOT + '/search';
    let params = new HttpParams();
    params = params.set('consultoID', this.consulto.ID.toString());
    const obs = this.http.get<Slot>(url, { params: params });
    this.sub.add(
      obs.subscribe((slot) => {
        if (slot) {
          this.slotAssociato = slot;
        }
      })
    );
  }

  private recuperaPazienti(utente?: string, id?: number) {
    const url = ApiUrl.PAZIENTI;
    if (this.currentUser.currentUser == undefined) {
      return;
    }
    let params: HttpParams = new HttpParams();
    if (utente != undefined) {
      params = params.set('cliente', this.currentUser.currentUser.Email);
    }
    const obs = this.http.get<Paziente[]>(url, { params: params });
    this.sub.add(
      obs.subscribe((pazienti) => {
        this.pazienti = pazienti;
        if (id) {
          this.consulto.PazienteID = id;
          let p = this.pazienti.find((p) => p.Id == id);
          if (p != undefined) {
            this.consulto.Paziente = p;
          }
        }
      })
    );
  }

  private recuperaUtenti() {
    const url = ApiUrl.USERS;
    let params: HttpParams = new HttpParams();
    params = params.set('ruolo', ruoloCliente);
    const obs = this.http.get<User[]>(url, { params: params });
    this.sub.add(
      obs.subscribe((users) => {
        this.utenti = users;
      })
    );
  }

  slotScelto(slot: Slot | undefined) {
    if (slot != undefined) {
      this.consulto.Slot = slot;
      this.slotAssociato = slot;
    } else {
      this.consulto.Slot = undefined;
      this.slotAssociato = undefined;
    }
    this.modifiche.modificheNonSalvate.next(true);
  }

  private recuperaTipiConsulto() {
    const url = ApiUrl.TIPI_CONSULTO;
    const obs = this.http.get<TipoConsulto[]>(url);
    obs.subscribe((tipiConsulto) => {
      this.tipiConsulto = tipiConsulto;
    });
  }

  protected get pazientiFiltered(): Paziente[] {
    if (this.utenteSelezionato == undefined) {
      return this.pazienti;
    }
    let p = this.pazienti.filter((p) => {
      return p.UserEmail == this.utenteSelezionato?.Email;
    });
    return p;
  }

  private goBack() {
    if (this.tornaAlCalendarioAlGiorno != undefined) {
      this.router.navigate(['../calendario'], {
        state: { dataInizioSettimana: this.tornaAlCalendarioAlGiorno },
      });
    } else {
      this.router.navigate(['../'], { relativeTo: this.actRoute });
    }
  }
  protected close() {
    this.goBack();
  }

  protected salva() {
    console.log('SALVA CONSULTO');
    this.unsavedChanges = false;
    // Se il consulto è nuovo, lo inserisco, altrimenti lo modifico
    if (this.isNew) {
      this.inserisci();
    } else {
      this.modifica();
    }
  }

  /**
   * inserisci si occupa di inserire il consulto nel db
    * Se il consulto ha uno slot associato, allora si tratta di un appuntamento
    * Se il consulto non ha uno slot associato, allora si tratta di un consulto
    Gestiamo gli allegati qui perchè ci serve consultoID come chiave esterna.
    Il metodo gestisciAllegatiSuS3 si occupa di gestire gli eventuali errori e di chiamare il metodo di fallback
    se il caricamento dell'allegato non va a buon fine
   */
  private inserisci() {
    if (this.consulto.Slot != undefined) {
      //Salvo lo slot con il consulto associato
      const url = ApiUrl.SLOT;
      const obs = this.http.put<Slot>(url, this.consulto);
      obs.subscribe(async (res) => {
        let consultoInserito = this.handleConsultoResponse(res.Consulto!);
        this.gestisciAllegatiSuS3(consultoInserito).subscribe((_) => {
          this.messaggioInserimento('Appuntamento inserito con successo');
          this.goBack();
        });
      });
    } else {
      //Salvo il consulto
      const url = ApiUrl.CONSULTI;
      const obs = this.http.post<Consulto>(url, this.consulto);
      obs.subscribe(async (res) => {
        let consultoInserito = this.handleConsultoResponse(res);
        //Una volta sistemata la struct, possiamo chiamare il metodo per gestire gli allegati
        this.gestisciAllegatiSuS3(consultoInserito).subscribe((_) => {
          // L'inserimento di un consulto non è subordinato all'inserimento corretto dei suoi allegati
          // Quindi qui non ci interessa gestire eventuali errori
          // Il metodo gestisciAllegatiSuS3 si occupa di gestire gli errori e di chiamare il metodo di fallback
          // e di dare l'avviso all'utente se il caricamento dell'allegato non va a buon fine
          this.messaggioInserimento('Consulto inserito con successo');
          this.goBack();
        });
      });
    }
  }

  private modifica(senzaSlot: boolean = false) {
    if (this.consulto.Slot != undefined) {
      //Salvo lo slot con il consulto associato
      const url = ApiUrl.SLOT;
      let obs: Observable<Slot | Consulto>;
      if (senzaSlot) {
        obs = this.http.put<Consulto>(url, this.consulto);
      } else {
        this.slotAssociato!.ConsultoId = this.consulto.ID;
        this.consulto.Slot = this.slotAssociato;
        obs = this.http.put<Slot>(url, this.consulto);
      }
      obs.subscribe(async (res) => {
        let consultoModificato: Consulto;
        if (senzaSlot) {
          consultoModificato = this.handleConsultoResponse(res as Consulto);
        } else {
          consultoModificato = this.handleConsultoResponse(
            (res as Slot).Consulto!
          );
        }
        this.gestisciAllegatiSuS3(consultoModificato).subscribe((res) => {
          let m = 'Appuntamento modificato con successo';
          this.messaggioModifica(m);
          this.goBack();
        });
      });
    } else {
      const url = ApiUrl.CONSULTI + '/' + this.consulto.ID;
      const obs = this.http.put<Consulto>(url, this.consulto);
      obs.subscribe(async (res) => {
        let consultiModificato = this.handleConsultoResponse(res);
        this.gestisciAllegatiSuS3(consultiModificato).subscribe((res) => {
          let m = 'Consulto modificato con successo';
          this.messaggioModifica(m);
          this.goBack();
        });
      });
    }
  }

  /**
  handleConsultoResponse si occupa di aggiornare i campi ToAdd e ToDelete degli allegati
  * ATTENZIONE: La action lato server che inserisce il consulto, 
  si occupa anche di ricaricari il consulto inserito aggiungendo in preload gli allegati caricati
  (Questo perchè ci servono gli id degli allegati salvati su db dalla action)
  Tuttavia, la struct lato server ignora i campi ToAdd e ToDelete.
  Dobbiamo quindi aggiornare i campi ToAdd e ToDelete con quelli presenti nel consulto
  e aggiungere il File.
*/
  handleConsultoResponse(rispServer: Consulto): Consulto {
    if (rispServer.Allegati != undefined && rispServer.Allegati.length > 0) {
      for (let i = 0; i < rispServer.Allegati!.length; i++) {
        const element = rispServer.Allegati![i];
        const all = this.consulto.Allegati?.find(
          (el) => el.AwsID == element.AwsID
        );
        if (all != undefined) {
          element.ToAdd = all.ToAdd;
          element.ToDelete = all.ToDelete;
          element.File = all.File;
        }
      }
    }
    return rispServer;
  }

  /**
  gestisciAllegatiSuS3 si occupa di gestire gli allegati del consulto
  ritorna un forkJoin con due oggetti: added e deleted
  Se uno dei due array è vuoto, si ritorna un observable vuoto
  Inoltre se i metodi inviaAllegatiAS3 o eliminaAllegatodaS3
  ritornano un errore, si chiama il metodo allegatiFallBack
   */
  gestisciAllegatiSuS3(consulto: Consulto): Observable<any> {
    if (consulto.Allegati == undefined) {
      return of();
    }
    let obsAdd: Observable<any>[] = [];
    let obsDelete: Observable<any>[] = [];
    for (let i = 0; i < consulto.Allegati.length; i++) {
      if (consulto.Allegati[i].ToAdd) {
        let allegato = consulto.Allegati[i];
        if (allegato.File == undefined) {
          continue;
        }
        obsAdd.push(
          this.inviaAllegatoAS3(allegato, allegato.File).pipe(
            catchError((err) => {
              console.log(err);
              this.allegatiFallBack(consulto.Allegati![i].ID);
              return of(null);
            })
          )
        );
        continue;
      }
      if (consulto.Allegati[i].ToDelete) {
        obsDelete.push(
          this.eliminaAllegatoDaS3(consulto.Allegati[i].AwsID!).pipe(
            catchError((err) => {
              console.log(err);
              this.allegatiFallBack(consulto.Allegati![i].ID);
              return of(null);
            })
          )
        );
      }
    }
    // Si ritorna un forkJoin con due oggetti: added e deleted
    // Se uno dei due array è vuoto, si ritorna un observable vuoto
    return forkJoin({
      added: obsAdd.length > 0 ? forkJoin(obsAdd) : of([]),
      deleted: obsDelete.length > 0 ? forkJoin(obsDelete) : of([]),
    });
  }

  /**
   * allegatiFallBack si occupa di eliminare l'allegato dal db
  se il caricamento dell'allegato su s3 non va a buon fine
   * @param AllegatoID 
   */
  allegatiFallBack(AllegatoID: number) {
    console.log('FALLBACK');
    // elimino l'allegato dal db
    const obs = this.http.delete(ApiUrl.ALLEGATI + '/db/' + AllegatoID);
    obs.subscribe((_) => {
      let m: MessageData = MessageConstants.Errore;
      m.Message =
        "Non è stato possibile caricare l'allegato sullo storage. Per favore riprova.";
      setTimeout(() => {
        this.messageService.sendMessage(m);
      }, 1000);
    });
  }

  private inviaAllegatoAS3(
    allegato: Allegato,
    formData: File
  ): Observable<any> {
    //TO SIMULATE AN ERROR  return throwError(() => new Error('simulate error'));
    return this.http.put<any>(allegato.AwsUrl!, formData);
  }

  private eliminaAllegatoDaS3(allegatoId: string): Observable<string> {
    let url = ApiUrl.ALLEGATI;
    let param = new HttpParams().set('idAllegato', allegatoId);
    return this.http.delete<any>(url, { params: param });
  }
  private recuperaUrlDownload(id: string): Observable<Allegato> {
    let url: string = ApiUrl.ALLEGATI_DOWNLOAD + '/' + id;
    return this.http.get<Allegato>(url);
  }

  messaggioModifica(m: string) {
    let mD: MessageData = MessageConstants.Modifica;
    mD.Message = m;
    this.messageService.sendMessage(mD);
  }
  messaggioInserimento(m: string) {
    let mD: MessageData = MessageConstants.Inserimento;
    mD.Message = m;
    this.messageService.sendMessage(mD);
  }

  allRefertiUndefined(): boolean {
    return (
      this.consulto == undefined ||
      (this.consulto.Ecg == undefined &&
        this.consulto.Ecocardiografia == undefined &&
        this.consulto.Ecografia == undefined &&
        this.consulto.Holter == undefined &&
        this.consulto.Radiografia == undefined &&
        this.consulto.SmallParts == undefined)
    );
  }

  getLabelThirdStep(): string {
    if (
      this.currentUser == undefined ||
      this.currentUser.currentUser == undefined
    ) {
      return '';
    }
    if (
      this.currentUser.currentUser.Ruolo == ruoloCliente &&
      this.allRefertiUndefined()
    ) {
      return 'Dettaglio paziente e cliente';
    }
    if (this.currentUser.currentUser == undefined) {
      return '';
    }
    if (this.consulto == undefined) {
      return '';
    }
    return (
      this.tipiConsulto.find((t) => t.ID == this.consulto.TipoConsultoID)
        ?.Descrizione || ''
    );
  }

  get isCliente(): boolean {
    return this.currentUser.currentUser?.Ruolo == ruoloCliente;
  }

  get showReferto(): boolean {
    //Se consulto.ID è undefined, il consulto non è stato salvato, quindi non posso mostrare il referto
    if (this.isCliente) {
      return this.isOneRefertoDisponibile();
    } else {
      return true;
    }
  }

  isOneRefertoDisponibile(): boolean {
    return (
      (this.consulto.Ecg != undefined && this.consulto.Ecg.Disponibile) ||
      (this.consulto.Ecocardiografia != undefined &&
        this.consulto.Ecocardiografia.Disponibile) ||
      (this.consulto.Ecografia != undefined &&
        this.consulto.Ecografia.Disponibile) ||
      (this.consulto.Holter != undefined && this.consulto.Holter.Disponibile) ||
      (this.consulto.Radiografia != undefined &&
        this.consulto.Radiografia.Disponibile) ||
      (this.consulto.SmallParts != undefined &&
        this.consulto.SmallParts.Disponibile)
    );
  }

  metodoPagamentoChanged(MetodoScelto: number) {
    let tipoConsultoCostoTC =
      this.tipiConsulto.find((t) => t.ID == this.consulto.TipoConsultoID)
        ?.CostoTc || 0;
    let tipoConsultoCosto =
      this.tipiConsulto.find((t) => t.ID == this.consulto.TipoConsultoID)
        ?.Costo || 0;
    let p: Pagamento = {
      ConsultoID: this.consulto.ID,
      TipoPagamento: MetodoScelto,
      Importo: this.slotAssociato ? tipoConsultoCostoTC : tipoConsultoCosto,
      Stato: pagamentoSospeso,
    };
    this.consulto.Pagamento = p;
    this.modifiche.modificheNonSalvate.next(true);
  }
  pagamentoPaypalEffettuato(ev: datiPagamentoPaypal | undefined) {
    this.consulto.Pagamento!.Stato = pagamentoEffettuato;
    if (ev != undefined) {
      this.consulto.Pagamento.PaypalOrderID = ev.data?.orderID;
      this.consulto.Pagamento.PaypalPayerID = ev.data?.payerID;
    }
  }

  get salvaConsultoDisabled() {
    //Se i dati del pagamento non ci sono
    if (this.consulto.Pagamento == undefined) {
      return true;
    }
    //Se ho scelto paypal e il pagamento è sospeso
    if (
      this.consulto.Pagamento.TipoPagamento == pagamentoPaypal &&
      this.consulto.Pagamento.Stato == pagamentoSospeso
    ) {
      return true;
    }
    // altrimenti ho scelto bonifico e posso salvare il consulto in attesa di pagamento
    return false;
  }

  aggiungiPaziente() {
    this.sub.add(
      this.dialog
        .open(PazientiDetailComponent, {
          data: { pazienteID: 'new', clienteEmail: this.consulto.UserEmail },
          minWidth: '70vw',
        })
        .afterClosed()
        .subscribe((res: Paziente) => {
          this.controllaRuoloERecuperaPazienti(res.Id);
        })
    );
  }

  protected isSaveDisabled() {
    return !this.firstStepCompleted;
  }

  // ALLegati
  /**
   *
   * @param ev
   * @returns
   */
  allegatiChanged(ev: Allegato[]) {
    if (this.consulto.Allegati == undefined) {
      this.consulto.Allegati = [];
    }
    console.log(this.consulto.Allegati);

    for (let i = 0; i < ev.length; i++) {
      const allegatoDaCaricare = ev[i];
      let index = this.consulto.Allegati?.findIndex(
        (el) => el.ID == allegatoDaCaricare.ID
      );
      if (index == -1) {
        allegatoDaCaricare.ToAdd = true;
        allegatoDaCaricare.ID = 0;
        this.consulto.Allegati?.push(allegatoDaCaricare);
      }
    }
  }

  eliminaAllegato(ev: Allegato) {
    let index = this.consulto.Allegati?.findIndex((el) => el.ID == ev.ID);
    if (index != -1 && index != undefined) {
      // TO FORCE CHANGE DETECTION STRATEGY
      let updatedAllegato: Allegato = {
        ...this.consulto.Allegati![index],
        ToDelete: true,
      };
      // Replace the old Allegato object with the new object
      this.consulto.Allegati![index] = updatedAllegato;
    }
  }

  protected async deleteConsulto() {
    if (this.consulto == undefined || this.isNew) {
      return;
    }
    // Se è presente una fattura, non posso eliminare il consulto
    if (hasFattura(this.consulto.Pagamento)) {
      this.avvisaPresenzaFattura();
      return;
    }
    // Se il pagamento è stato effettuato, avviso l'utente
    if (
      this.consulto.Pagamento != undefined &&
      this.consulto.Pagamento.Stato == pagamentoEffettuato
    ) {
      const res = await lastValueFrom(this.avvisaPresenzaPagamento());
      if (res) {
        this.serverDelete();
        return;
      } else {
        return;
      }
    }
    // Se non c'è un pagamento effettuato, ne una fattura associata, chiedo comunque conferma
    const res = await lastValueFrom(this.askConfirmForDelete());
    if (res) {
      this.serverDelete();
    }
  }

  private serverDelete() {
    const url = ApiUrl.CONSULTI + '/' + this.consulto.ID;
    const obs = this.http.delete(url);
    this.sub.add(
      obs.subscribe((_) => {
        this.messageService.sendMessage(MessageConstants.Eliminazione);
        this.goBack();
      })
    );
  }

  private avvisaPresenzaPagamento() {
    let m: ConfirmDialogData = {
      message: [
        'Attenzione: è presente un pagamento con stato EFFETTUATO, per questo consulto.',
        'Sei sicuro di voler eliminare il consulto?',
      ],
      showCancel: true,
      title: 'Elimina consulto',
    };
    return this.dialog.open(ConfirmDialogComponent, { data: m }).afterClosed();
  }

  private avvisaPresenzaFattura() {
    let m: MessageData = MessageConstants.Errore;
    m.Message =
      'Attenzione: è presente una fattura per questo consulto. Non è possibile eliminare il consulto.';
    this.messageService.sendMessage(m);
  }

  private askConfirmForDelete() {
    let d: ConfirmDialogData = {
      message: ['Sei sicuro di voler eliminare il consulto?'],
      showCancel: true,
      title: 'Elimina consulto',
    };
    return this.dialog.open(ConfirmDialogComponent, { data: d }).afterClosed();
  }

  async canDeactivate(): Promise<boolean> {
    if (this.unsavedChanges) {
      return await lastValueFrom(
        of(
          confirm('Ci sono modifiche non salvate. Sei sicuro di voler uscire?')
        )
      );
    }
    return await lastValueFrom(of(true));
  }
}
