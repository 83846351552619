import { User } from "../user.model";

export type Ecocardiografia = {
  ID: number;
  ConsultoID: number;
  MotivoEsame?: string;
  Anamnesi?: string;
  Note?: string;
  Sensorio?: string;
  Mucose?: string;
  FrequenzaRespiratoria?: string;
  SoffiCardiaci?: string;
  FrequenzaCardiaca?: string;
  Trc: string;
  MModeVentricoloSinistroDiastolesettoivd?: string;
  MModeVentricoloSinistroDiastoledvsxd?: string;
  MModeVentricoloSinistroDiastoleplvsxd?: string;
  MModeVentricoloSinistroSSettoivs?: string;
  MModeVentricoloSinistroSDvsxs?: string;
  MModeVentricoloSinistroSPlvsxs?: string;
  MModeVentricoloSinistroFa?: string;
  MModeVentricoloSinistroFe?: string;
  MModeVentricoloSinistroEsvi?: string;
  MModeVentricoloSinistroEdvi?: string;
  MModeVentricoloSinistroFcard?: string;
  MModeVentricoloSinistroLvidd?: string;
  MModeVentricoloSinistroEpss?: string;
  BModeDiamaorta?: string;
  BModeDiampolmonare?: string;
  BModeAoatriosxao?: string;
  BModeAsxatriosxao?: string;
  BModeAsxaoatriosxao?: string;
  BModeLadatriosxao?: string;
  Dopplermitralevelpe: string;
  Dopplermitralevelpa: string;
  Dopplermitralerigmitralico: string;
  Doppleraortafvilvot: string;
  Doppleraortavelpao: string;
  Doppleraortagradp: string;
  Dopplertricuspidetrrig: string;
  Dopplertricuspidegraditr: string;
  Dopplerpolmonarefvirvot: string;
  Dopplerpolmonarevelpp: string;
  Dopplerpolmonaregradp: string;
  Referto?: string;
  Diagnosi: string;
  Terapia?: string;
  EseguitoDa?: string;
  EseguitoDaUser?: User;
  Disponibile: boolean;
  CreatedAt: Date;
  UpdatedAt: Date;
};

export type EcocardiografiaUnit = {
  MModeVentricoloSinistroDiastolesettoivd?: string;
  MModeVentricoloSinistroDiastoledvsxd?: string;
  MModeVentricoloSinistroDiastoleplvsxd?: string;
  MModeVentricoloSinistroSSettoivs?: string;
  MModeVentricoloSinistroSDvsxs?: string;
  MModeVentricoloSinistroSPlvsxs?: string;
  MModeVentricoloSinistroFa?: string;
  MModeVentricoloSinistroFe?: string;
  MModeVentricoloSinistroEsvi?: string;
  MModeVentricoloSinistroEdvi?: string;
  MModeVentricoloSinistroFcard?: string;
  MModeVentricoloSinistroLvidd?: string;
  MModeVentricoloSinistroEpss?: string;
  BModeDiamaorta?: string;
  BModeDiampolmonare?: string;
  BModeAoatriosxao?: string;
  BModeAsxatriosxao?: string;
  BModeAsxaoatriosxao?: string;
  BModeLadatriosxao?: string;
  Dopplermitralevelpe?: string;
  Dopplermitralevelpa?: string;
  Dopplermitralerigmitralico?: string;
  Doppleraortafvilvot?: string;
  Doppleraortavelpao?: string;
  Doppleraortagradp?: string;
  Dopplertricuspidetrrig?: string;
  Dopplertricuspidegraditr?: string;
  Dopplerpolmonarefvirvot?: string;
  Dopplerpolmonarevelpp?: string;
  Dopplerpolmonaregradp?: string;
};

export const ecocardiografiaUnits: EcocardiografiaUnit = {
  MModeVentricoloSinistroDiastolesettoivd: 'cm',
  MModeVentricoloSinistroDiastoledvsxd: 'cm',
  MModeVentricoloSinistroDiastoleplvsxd: 'cm',
  MModeVentricoloSinistroSSettoivs: 'cm',
  MModeVentricoloSinistroSDvsxs: 'cm',
  MModeVentricoloSinistroSPlvsxs: 'cm',
  MModeVentricoloSinistroFa: '%',
  MModeVentricoloSinistroFe: '%',
  MModeVentricoloSinistroEsvi: 'ml/m2',
  MModeVentricoloSinistroEdvi: 'ml/m2',
  MModeVentricoloSinistroFcard: '',
  MModeVentricoloSinistroLvidd: 'norm.<=1.7',
  MModeVentricoloSinistroEpss: 'cm',
  BModeDiamaorta: 'cm',
  BModeDiampolmonare: 'cm',
  BModeAoatriosxao: 'cm',
  BModeAsxatriosxao: 'cm',
  BModeAsxaoatriosxao: '',
  BModeLadatriosxao: 'cm',
  Dopplermitralevelpe: 'm/s',
  Dopplermitralevelpa: 'm/s',
  Dopplermitralerigmitralico: 'm/s',
  Doppleraortafvilvot: 'cm',
  Doppleraortavelpao: 'm/s',
  Doppleraortagradp: 'mmHg',
  Dopplertricuspidetrrig: 'm/s',
  Dopplertricuspidegraditr: 'mmHg',
  Dopplerpolmonarefvirvot: 'cm',
  Dopplerpolmonarevelpp: 'm/s',
  Dopplerpolmonaregradp: 'mmHg',
};
